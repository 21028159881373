//@import url('https://fonts.googleapis.com/css?family=Montserrat:300,300i,500,600,700');
@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,700');
@import "sass/color";

body {
  margin: 0;
  padding: 0;
  font-family: 'PT Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  color: #333;
}

a{
  cursor: pointer;
}

ul{
  padding: 0;
  margin: 0;
}


#myVideo {
	position: absolute; 
	right: 0; 
	bottom: 0;
	min-width: 100%; 
	min-height: 100%;
	width: auto; 
	height: auto; 
	z-index: -1000;
	background-size: cover; 
}

.imageHero {
    text-align: center;
    height: 100vh; 
    //background-image: url("https://picsum.photos/1024/500?image=2");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-x: hidden;
    overflow-y: hidden;
    img {
        width: auto;
        height: 100%;
        -webkit-transition: all .3s linear;
        transition: all .3s linear;
        opacity: 100%;
    }
}

.slider-control-bottomcenter{
  display: none;
}

.slider-control-centerleft:hover{
   left: 5px !important;
}
.slider-control-centerright:hover{
   right: 5px !important;
}
.slider-control-centerright,.slider-control-centerleft{
  font-size: 5rem;
  transition: all .3s linear;
  &:hover{
    font-size: 6rem;
    color: #333;
  }
}
section{
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: center;
    padding: 5% 0;
    min-height: 100vh;
}

h2.session-title{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
    text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
    text-rendering: optimizeLegibility !important;
    font-weight: 700;
    text-align: center;
    display: inline-block;
    position: relative;
    //margin-top: 3rem;
    margin-bottom: 3rem;
    &:after{
        content: '';
        text-align: center;
        margin: 0 auto;
        background: $grafite;
        position: relative;
        width: 75%;
        height: 3px;
        display: block;
    }
}
.slider div, a{
  //eviar o highlight azul no chrome android
  -webkit-tap-highlight-color: transparent !important;
  cursor: pointer;
}
#portifolio{
    .slider-frame{
      width: 80%;
      margin: auto !important;
    }
		a.panelControl{
			margin: 0px 0.2rem;
	    color: #000;
	    border-radius: 5px;
	    display: inline-block;
	    font-size: .9rem;
	    font-weight: 600;
	    //border: 1px solid #dedede;
	    transition: all 0.3s linear;
	    padding: 1rem 0;
	    margin: 1rem auto;
	    text-align: center;
      width: 100%;
      display: flex;
      height: 2rem;
      justify-content: center;
      align-items: center;

      &:active, &:focus, &:hover{
        background-color: white;
        color: #000;
			}

		}
}