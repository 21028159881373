.masonry-container{
	width: 90%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	.item-portifolio{
		height: 50vw;
		@media (min-width: 900px) {
			height: 20vw;
		}
	    width: auto;
		overflow-y: hidden;
		overflow-x: hidden;
		flex: 1 1 100%;
		max-width: 100%;
		margin-bottom: 3px;
	
		@media (min-width: 900px) {  
			flex: 1 1 33%;
			max-width: 33%;
		}
	}
}
.tile{
	position: relative;
	margin: 4px;
	overflow-y: hidden;
	overflow-x: hidden;
	transition: all .3s linear;
	min-height: 100%;
	p{
		position: absolute;
		z-index: 99;
		right: -999px;
		bottom: 0;
		color: white;
		transition: right .6s linear;
	}
	&:after{
		content:'';
		background-color: rgba(0,0,0,0);
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		width: 100%;
		height: 100%;
	    transition: background-color .3s linear;
	}
	
	&:hover{
		@media (min-width: 900px) {
			p{ right: 15px;	}
			img{ transform: scale(1.3); }
			&:after{
				content:'';
				background-color: rgba(0,0,0,0.88);
			}
		}
	}
	img{
		position: absolute;
		top: 0;
		height: auto;
		width: 100%;
		bottom: 0;
		margin: auto;
		box-shadow: 0 1px 1px 2px rgba(0,0,0, .15);
		//border-radius: 10px;
		transform: scale(1.4);
		@media (min-width: 900px) {
			height: auto;
			width: 100%;
		}
		transition: transform .3s linear;
	}
}
.close-btn{
    position: fixed;
    top: .5rem;
    right: 2rem;
    z-index: 9999999;
    color: white;
    cursor: pointer;
    font-size: 2rem;
}
.ril-toolbar.ril__toolbar{
	display: none;
}
