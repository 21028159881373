@import "../../sass/color";
@import "../../sass/form";

#contato{
    background-color: white;
    background-size: cover;
    background-position: center;
    min-height: 70vh;
}
.flex{ 
    display: flex;  
    flex-flow: column;
    @media screen and (min-width: 1200px) { flex-flow: row;}
}
.flex1{  flex: 1; }
.flex2{  flex: 2; }

.dataInfo{
    background-color: $grafite;
    padding: 2rem;
    min-width: 15rem;
    color: #fff;
    order: 2;
    @media screen and (min-width: 1200px) { 
        flex-flow: row; 
        order: -1;
    }
    p a{
        text-decoration: none;
        color: #abd37a;
    }
    
}
.fundoPreto{
    display: none;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
}

 .userMessage{
    align-items: center;
    justify-content: center;
    display: none;
    opacity: 0;
    visibility:hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 100;
    width: 75%;
    height: 20%;
    background-color: #fff;
    border: 1px solid #dedede;
    left: 0;
    right: 0;
    margin: auto;
    padding: 2rem;
    text-align: center;
    transition: all .3s linear;
    p{
        font-size: 1.4rem;
    }
    a{
        position: absolute;
        top: 0;
        right: 0;
        padding: 2rem;
    }
}


.nightMode{
    #contato{
        background-color: $grafite;
    }
    .dataInfo{
        background-color: white;
        color: $grafite;
        p a{
            color: $grafite;
        }
        
    }
    .outrosContatos a,.outrosContatos p{
        color: white;
        text-decoration: none;
    }
}

.outrosContatos{
    margin: 3rem;
    p{
        font-weight: 300;
        margin: 0;
        padding: 0;
        font-size: 1rem;
        a{
            color: $grafite;
            text-decoration: none;
        }
    }
}