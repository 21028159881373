@import "../../sass/color";

.container{
    background-color: white;
    padding: 2rem;
    .QuemSomos{ 
        //background-image: url("https://via.placeholder.com/1000/fff/fff");
        background-size: cover;
        background-position: center;
        display: flex;  
        flex-flow: column;
        @media screen and (min-width: 900px) { 
            margin: 6rem auto;
            width: 65%;
        }
        
        &>div{
            display: flex;
            flex-flow: column;
            h1{
                padding: 0  2rem;
                color: $grafite;
                display: flex;
                text-align: center;
                margin: auto 0;
                padding-bottom: 1rem
            }
            p{
                padding: 0 2rem;
                color: $grafite;
                font-size: 1.1rem;
                display: flex;
                font-weight: 300;
                text-align: center;
                @media screen and (min-width: 1200px) { text-align: left;}
                margin: auto 0;
            }
        }
        .row{
            display: flex;
            flex-flow: row; 

            
            width: 100%;
            padding: 2rem;
            justify-content: space-between;
            .membrana{
                overflow-x: hidden;
                overflow-y: hidden;
                height: 15rem;
                width: 30%;
                position: relative;
                img{
                    width: auto;
                    height: 100%;
                    margin: auto;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
            }

        }
    }

}
.nightMode{
    .container{
        background-color: $grafite;
        .QuemSomos{
            //background-image: $grafite;
            p,h1{
                color: white;
            }
        }
    }

}