$white: #fff;
$grafite: #141414;


.dayMode{
  ul{
    background-color: $white !important;
    transition: all .3s linear;
    &.open{  background-color: $white !important; transition: all .3s linear;}
  }

}
.nightMode{
  .gambi{
    color: $white !important;
    background-color: $grafite !important;
    transition: all .3s linear;
  }
  section{
      color: $white !important;
      background-color: $grafite !important;
      transition: all .3s linear;
      h2{  
        color: $white !important; 
         &:after{  background: $grafite }
      }
      
  }
  #portifolio  li a{
    color: $white !important;
    &:active, &:focus, &:hover{
      color: $grafite !important;
      background-color: $white !important;
    }
  }
  
  ul{
    background-color: $grafite !important;
    transition: all .3s linear;
    &.open{  
      background-color: $grafite !important; 
      transition: all .3s linear;
      a{
        color: $white !important;
      }
    }
  }
}


.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 6px;
  box-sizing: border-box;
  border: 26px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}