@import "../../sass/color";
footer{
    background-color: $grafite;
    margin: 0;
    color: white;
    display: flex;
    justify-content: center;
    flex-flow: column;
    padding: 1rem;
    p{
        margin: 0;
        padding: 0;
    }
    .center{
        text-align: center;
        padding: .5rem;
    }
    a{
        text-decoration: none;
        color: white;
        font-size: 1.5rem;
        padding: 1rem;
    }
    span.imgContainer{
        margin: auto;
        padding: .5rem;
        text-align: center;
        img{
            height: auto;
            width: 20%;
        }
    }
}

footer.dayMode{
    background: white;
    color: $grafite;
    transition: all .3s linear;
    a{  color: $grafite; }
}