@import "../../sass/color";
@import "../../sass/form";

.Inovacao{
    //background-color: $white;
    background-color: white;
    .inovacaoInner{
        min-height: 70vh;
        display: flex;  
        flex-flow: row;
        align-items: center;
        flex-wrap: wrap;
        width: 90%;
        padding: 5% 0;
        margin: 0 auto;
    }
    .tile{
        flex: 1 1 33%;
        max-width: calc(33% - 4px);
        
        overflow-x: hidden;
        min-height: 300px;
        
        position: relative;
        @media (max-width: 900px){
            
            flex: 1 1 100%;
            max-width: 100%;
        }
        img{
            margin: 4px;
            position: absolute;
            top: 0;
            height: auto;
            width: 100%;
            bottom: 0;
            margin: auto;
        }
    }

    .blackback{
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0,0,0,0.5);
        .popup{
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 90vw;
            height: 50vh;
            @media (min-width: 900px){
                width: 50vw;
                height: 80vh;
            }
            margin: 0 auto;
            background: #fff;
            iframe{
                position: relative;
                display: block;
                width: 100%;
                height: 100%;
                margin: 0rem;
            }
        }
    }
}

.nightMode{
    .Inovacao{
        background-color: $grafite;
        color: white;
    }
}