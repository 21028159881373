form{
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    color: $white;
    margin: auto;
    h1{
        color: $grafite;
        font-weight: bold;
    }
    p{
        color: $grafite;
        font-size: 1.5rem;
        margin: 0;
        padding: 0;
        text-align: center;
        font-weight: bold;
        padding-bottom: 1rem;
    }
    .flexSpan{
        display: flex;
        flex-flow: column;
    }
    .input-group{
        display: flex;
        position: relative;
        height: 3rem;
        width: 20rem;
        justify-content: center;
        flex-direction: column;
        margin: 7px 1rem;
        .label{
            position: absolute;
            top: 0px;
            left: 0;    
            color: $grafite;
            right: 0;
            z-index: 1;
            width: 100%;
            font-size: 0px;
            margin: 1rem auto;
            text-align: center;
            transition: font-size .3s linear, top .3s linear;
        }
        #name,#email,#login,#senha{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            z-index: 0;
            height: 1rem;
            margin: 1rem auto;
        }
        #name,#email,textarea,#login,#senha{
            border: none;
            border-bottom: 1px solid transparent;
            transition: border .3s linear;
            background-color: #e7e7e7;
            padding: 0.5rem 0;
            text-align: center;
            border-radius: 4px;
             &:focus{
                border-bottom: 1px solid green;
                outline: none;
            }
        }
        #submit{
            text-decoration: none;
            background: $grafite;
            border: 0;
            color: white;
            border-radius: 5px;
            font-size: 1.1rem;
            padding: 1rem;
        }
        &.textArea{
            height: 6rem;
            textarea{ 
                overflow-x: hidden;
                resize: vertical;  
                margin: 1rem 0; 
            }
        }
    }
   
}

.nightMode{
    form{
        color: $grafite;
        h1{ color: white; }
        p{ color: white; }
        .input-group{
            .label{ color: white; }
            #submit{
                background: white;
                color: $grafite;
            }
        }
    }
}