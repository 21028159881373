.menu{
  z-index: 101;
  .fundoPreto{
    display: none;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
  }
  .flags{
    user-select: none;
    padding-bottom: 1rem;
    padding-left: 1rem;
  }
}


ul {
  display: flex;
  list-style-type: none;
  //justify-content: flex-end;
  position: absolute;
  flex-flow: column;
  align-items: flex-end;
  right: 0;
  top: 0;
  height: 100vh;
  width: 0;
  font-size: 1.5rem;
  transition: all .3s linear;

  &.open{
      position: fixed;
      width: 18rem;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      right: 0;
      top: 0;
      z-index: 101;
      transition: width .3s linear;
      li{
          text-align: right;
          opacity: 100%;
      }
      &+.fundoPreto{
          display: block;
      }
  }
  &.close{
      width: 0;
  }
}

li.menuItem {
    opacity: 100%;
    transition: opacity 3s linear;
    user-select: none;
    width: 100%;
    text-align: start;
    border-bottom: 1px solid $grafite;
    transition: all .3s linear;
    transition: height .1s linear;
    &.submenuOpen{
      width: 0%;
      height: 0%;
    }
    // &.submenuTitulo{

    // }
    &:first-child{
         border-top: 1px solid $grafite;
    }
    a {
        padding: 0.7rem 1rem;
        text-decoration: none;
        color: #666;
        //font-weight: 600;
        cursor: pointer;
        display: inline-block;
    }
}
li.submenuItem {
  opacity: 100%;
  transition: opacity 3s linear;
  user-select: none;
  width: 100%;
  text-align: start;
  font-size: 1.3rem;
  a {
      padding: 0.7rem 1rem;
      text-decoration: none;
      color: #666;
      //font-weight: 600;
      cursor: pointer;
      display: inline-block;
  }
}

.nightModeSwitch{
    position: absolute;
    bottom: 8%;
    left: 50%;
    width: 60px;
    height: 34px;

  input { 
      opacity: 0;
      width: 0;
      height: 0;
    }
    .sliderx {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #fff;
      -webkit-transition: all .3s linear;
      transition: all .3s linear;
      border-radius: 34px;
        &:focus {  
            outline:none;
            box-shadow: none;
        }

      &:before {
          background-image: url("./moon.png");
          position: absolute;
          content: "";
          height: 15px;
          width: 15px;
          left: 10px;
          bottom: 10px;
          //background-color: #000;
          -webkit-transition: .3s linear;
          transition: all .3s linear;
          border-radius: 50%;
        }
    }
    input:checked + .sliderx {
      background-color: #000;
      border: 1px dotted;
      &:before {
          background-image: url("./sun.png");
          height: 15px;
          width: 15px;
          left: 8px;
          bottom: 8px;
        }
    }
    input:checked + .sliderx:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
}