@import "../../sass/color";
@import "Menu";

header {
    display: block;
    background: white;
    height: 4.3rem;
    transition: all .3s linear;
    @media only screen and (min-device-width: 1366px) {
        height: 5rem;
    }
    &>div{
        margin-left: 5%;
        margin-right: 5%;
        padding-top: 5px;
        ul{
            .nav-open{
              padding: 1.5rem;
              @media only screen and (min-device-width: 1366px) {
                  padding: 1.5rem;
              }
              color: $grafite;
            }
            .nav-close{
              padding: 1.5rem;
              @media only screen and (min-device-width: 1366px) {
                  padding: 1.5rem;
              }
              color: #666;
            }
          }
        img{
            width: auto;
            height: 3rem;
            margin-top: .5rem;
            @media only screen and (min-device-width: 900px) {
                height: 3.5rem;
            }
        }
    }
}

header.nightMode{
    background-color: $grafite;
    color: white;
    transition: all .3s linear;
    ul{
        .nav-open{
          color: white;
        }
        .nav-close{
          color: $grafite;
        }
      }
}