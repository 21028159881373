@import "../../sass/color";

.container{
	display: flex;
	flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    min-height: 70vh;
	.item-portifolio{
        position: relative;
	    width: auto;
        flex: 1 1 100%;
        min-height: 15rem;
        @media (min-width: 900px) {
            height: 15vw;
            flex: 1 1 33%;
            max-width: 33%;
		}
        
        img{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: auto;
            width: 50%;
            bottom: 0;
            margin: auto;
            
            @media (min-width: 900px) {
                height: auto;
                width: 50%;
            }
        }
	}
}

.container.nightMode{
    background-color: $grafite;

}