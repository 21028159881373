@import "../../sass/color";
@import "../../sass/form";

.area-login{
    //background-color: $white;
    background-color: white;
    background-size: cover;
    background-position: center;
    min-height: 70vh;
    display: flex;  
    flex-flow: column;
    align-items: center;
}
.downloadContainer{
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    background-color: $white;
    .downloadAll{
       text-decoration: none;
        background-color:$grafite;
        border: 0;
        color: white;
        border-radius: 5px;
        font-size: 1.1rem;
        padding: 1rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    .downloadContainerList{
        background-color: white;
        width: 100%;
        display: flex;
        flex-flow: column;
        align-items: center;
        .downloadItem{
            padding: 0.5rem;
            background-color: $grafite;
            color: white;
            margin: 0.6rem;
            border-radius: 5px;
            cursor: pointer;
            text-align: center;
            font-weight: bold;
            font-size: 1rem;
            text-decoration: none;
        }
    }
}
    
.tile{
    span{
		position: absolute;
		z-index: 99;
		left: 0;
		transition: left .6s;
		color: white;
	}
}

input.carregando{
    color: white !important;
    transition: all .3s ease-out;
   background-color: rgb(94,94,136) !important;
}
.folderName{
    margin-top: 3rem;
    font-size: 1.7rem;
    color: $grafite !important;
}

.nightMode{
    .area-login{
        background-color: $grafite;
        color: white;
    }
    .folderName{
        color:  white !important;
    }
    .downloadContainer{
        background-color: $grafite;
        .downloadContainerList{
            background-color: $grafite;
            .downloadItem{
                background-color: white;
                color: $grafite;
            }
        }
    }
    .downloadAll{
        background-color: $white;
        color: $grafite;
    }
}